import React, { createRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {height: null}
    this.ref = createRef()
  }

  componentDidMount() {
    this.update()
    setTimeout(() => {
      this.update()
    }, 10)
    this.eventListener = () => this.update()
    window.addEventListener('resize', this.eventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.eventListener)
  }

  update() {
    if (this.ref.current) {
      this.setState({height: this.ref.current.clientHeight})
    }
  }

  render() {
    const Answer = this.props.item.answer
    return <div className={classNames("_84",  { "_85": this.props.showDescription })} key={this.props.item.question}>
      <dt>
        <button
          aria-expanded={this.props.ariaExpanded}
          aria-controls={`_az${this.props.index}`}
          className={`_86 _4h`}
          onClick={this.props.onClick}
        >
          {this.props.item.question}
        </button>
      </dt>
      <dd>
        <div
          style={{height: this.state.height}}
          id={`_az${this.props.index}`}
          className={classNames("_87", { "_89": !this.props.showDescription })}
        >
        <div
          ref={this.ref}
          className={"_88"}
        >
          <Answer></Answer>
        </div>
        </div>
      </dd>
    </div>
  }
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.node,
  }),
}

export default AccordionItem
