import React from "react"

import "./video.sass"

const Video = () => {

  return (
    <section>
      <div className={"_13"}>
        <div className={"_15"}>


          <div className="_b6">
            <h3 className={"_4d _8a"}>Der neue Imagefilm der Fahrschule Rauscher</h3>
            <div className={"_8b"}>
              <video src="/images/fahrschule-rauscher-image-video.mp4" preload="true" playsinline={true} controls={true} autoPlay={true} muted={true}
                     loop={true}></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Video
