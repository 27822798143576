import React from "react";

import './digital-license.sass';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const DigitalLicense = () => {

  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "digitaler-antrag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

  return <section className="_8c">
    <div className="_15">
          <div className="_8d">
            <a target={"_blank"}  href={"/Digitaler-Fuehrerscheinantrag.pdf"}>
            <Img className={"_8e"} fluid={data.file.childImageSharp.fluid}
                 alt={"Digitaler Führerscheinantrag"}></Img>
            </a>
            <div>
              <div className={"_4g mb-0 _8f"}>Jetzt Neu:</div><h1 className="_4d _8h">Digitaler Führerscheinantrag</h1>
              <p className="_8g">Anträge für die Ersterteilung eines Führerscheins können ab sofort online eingereicht werden. Weitere Antragsarten (v.a. BF17, Erweiterung, Umtausch) folgen.</p>

            <div className={"_8i"}>
              <a href={"https://digitalerführerscheinantrag.de/08.LKWN"} target={"_blank"} className={"_4t _4u"}>Zum Online-Antrag</a>
              <a href={"/Digitaler-Fuehrerscheinantrag.pdf"} target={"_blank"} className={"_4t _4u"}>Mehr Infos</a>
            </div>
            </div>

          </div>
    </div>

  </section>
}

export default DigitalLicense;
